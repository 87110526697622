class LambdaCopy
{

    static init()
    {

        $(".l-copy-button").click(function() {

            var copyType = $(this).attr("data-copy-source");

            if(copyType == "input")
            {
                var input = $(this).siblings('input');

                if(!input)
                {
                    console.warn('Cannot find closest input to copy from');
                    return;
                }

                var copyData = input.val();

                navigator.clipboard.writeText(copyData);

                LambdaToast.new().withIcon("fa-solid fa-copy").withTitle("Clipboard").withBody(`
                <p>The following data has been set on your clipboard</p>
                <code>${copyData}</code>
                `).show();

            }
            else if(copyType == "target")
            {
                var target = $(this).attr("data-copy-target");
                
                if(!target)
                {
                    console.warn(`Element does not have a valid target. Copy Type is set to 'Target'`);
                    console.warn($(this));
                    return;
                }

                var targetObj = $(target);

                if(targetObj.length == 0)
                {
                    console.warn('The specified copy target is invalid');
                    return;
                }

                var copyData = targetObj.val();

                navigator.clipboard.writeText(copyData);

                LambdaToast.new().withIcon("fa-solid fa-copy").withTitle("Clipboard").withBody(`
                <p>The following data has been set on your clipboard</p>
                <code>${copyData}</code>
                `).show();

            }
            else
            {
                console.error(`Invalid Copy Source: ${copyType}`);
            }


        });

    }

}

window.LambdaCopy = LambdaCopy;

$(document).ready(function() {

    LambdaCopy.init();

});